import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import List from "../../../components/custom-widgets/list";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Icon from "../../../components/custom-widgets/icon";
import LinearGradientStyle from "../../../components/best-banks/best-banks.module.scss";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";

const FreshStartSpanish = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "es-fresh-start-page-hero",
    ...getHeroImgVariables(imgData),
    altText: "Feliz pareja bailando juntos en la cocina.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Ten una Segunda Oportunidad con WaFd Bank"
          }
        },
        {
          id: 2,
          button: {
            id: "fresh-start-hero-cta-1",
            text: "Abre una Cuenta Nuevo Comienzo",
            url: "/open-an-account",
            class: "btn-white",
            style: {
              maxWidth: "fit-content"
            }
          }
        }
      ]
    }
  };

  const seoData = {
    title: "Cuenta Nuevo Comienzo: su segunda oportunidad en la banca",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Cuenta Nuevo Comienzo: su segunda oportunidad en la banca"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Comience de nuevo con la cuenta bancaria de segunda oportunidad de WaFd Bank. Si se le ha negado una cuenta bancaria, nuestra cuenta Fresh Start puede ayudarlo a volver a la normalidad."
      },
      {
        property: "og:title",
        content: "Cuenta Nuevo Comienzo: su segunda oportunidad en la banca"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/cuenta-nuevo-comienzo"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-fresh-start-03242023.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Cuenta Nuevo Comienzo (Fresh Start)"
    }
  ];

  const freshStartListItems = {
    accountName: "fresh-start-list",
    items: [
      {
        id: 1,
        text: "Tan solo un depósito inicial de $25"
      },
      {
        id: 2,
        text: "$4 cuota mensual de mantenimiento"
      },
      {
        id: 3,
        text: "Acceso a banca electrónica y banca móvil"
      },
      {
        id: 4,
        text: "Pagos de persona a persona"
      },
      {
        id: 5,
        text: "Ningun saldo mínimo"
      },
      {
        id: 6,
        text: "Ninguna restriccion extra por la tarjeta de débito"
      },
      {
        id: 7,
        text: "Ningun cobro de sobregiro o fundos insuficientes"
      },
      {
        id: 8,
        text: "Después de crear historia buena de la cuenta ascender a una cuenta de cheques tradicional"
      }
    ]
  };

  const FreshStartDisclaimerText = () => (
    <span id="fresh-start-disclaimer">
      * Cuenta Nuevo Comienzo está disponible a individuos quienes no pueden abrir una cuenta de cheques tradicional por
      un informe negativo de ChexSystems<sup>&reg;</sup> si la(s) cuenta(s) presentada(s) a ChexSystems no es/son
      classificada(s) como fraude y ha sido pagado en su totalidad. Clientes deben mantener sus Cuentas Nuevo Comienzo
      en "buena reputación". Una cuenta será considerada de no buena reputación si cualquier combinacion de dos (2) o
      más transacciónes de tarjeta de débito o de la cámara de compensación automática son presentadas que haría que la
      cuenta sobregira si son pagadas. Si ocurre, la tarjeta de débito puede ser desactivada y WaFd Bank puede juzgar
      que la cuenta es cerrada. Clientes quienes mantienen sus cuentas de "buena reputación" estarán eligibles para
      reciber otro productos y servicios poco después del aniversario de 12 meses de abrir la cuenta. Esta cuenta es
      excluida de cualquier promoción de "nueva cuenta" que está pasando al abrir la cuenta.
    </span>
  );

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Cuenta Nuevo Comienzo (Fresh Start)</h1>
        <h3>
          Si ha sido declinado por una cuenta bancaria en el pasado Nuevo Comienzo* le ayuda regresar al sistema
          bancario con una cuota mensual de mantenimiento, mínima. Diga adiós a cobros de cheques y gastos de
          transacción que puede ahorrar y acelerar sus metas financieras.
        </h3>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-sm-0">
              <h3 className="font-weight-semi-bold text-success">Recibe todo lo necesario en un solo lugar</h3>
              <List {...freshStartListItems} />
              <div className="my-4">
                <Link className="btn btn-primary" to="/open-an-account" id="fresh-start-open-account-cta">
                  Abre una Cuenta Nuevo Comienzo
                </Link>
              </div>
            </div>
            <div className="col-md-6 my-3">
              <StaticImage
                src="../../../images/thumbnail-fresh-start-03232023-730.jpg"
                alt="Madre e hija sonriéndose mientras pintan."
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
          </div>
          <p className="text-sm text-gray-70 d-none d-md-block" style={{ columnCount: "2" }}>
            <FreshStartDisclaimerText />
          </p>
        </div>
      </section>

      <StartSavingsSection />

      <section className="container d-block d-md-none">
        <p className="text-sm text-gray-60">
          <FreshStartDisclaimerText />
        </p>
      </section>
      <section id="bankon-section" className={`${LinearGradientStyle.bestBanksBg}`}>
        <div className="container">
          <div className="row">
            <div className="col-auto mb-3">
              <img
                style={{ width: "159px" }}
                src="../../../images/bankon-logo-03312023.svg"
                alt="Certificado Fresh Start BankOn de WaFd Bank."
              />
            </div>
            <div className="col-sm-7 col-md-8 col-lg-9">
              <h3 className="font-weight-semi-bold">Certificada por BankOn</h3>
              <p>La cuenta Nuevo Comienzo de WaFd Bank está certificada por BankOn.</p>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default FreshStartSpanish;

